<template>
  <div>
    <v-form class="casbee-home">
      <div class="select-longvity">
        <div class="longvity-title">{{ $t('casbee.label_lifespan') }}</div>
        <default-pull-down-menu
          v-model="age"
          :items="ageOption"
          :initialValue="age"
          placeHolderText=""
          width="80px"
          height="32px"
          class="casbee-longvity casbee-pulldown"
          :isCasbeePulldown="true"
          :disabled="isViewMode"
        />
      </div>
      <div class="select-structure">
        <div class="structure-title">{{ $t('casbee.label_structure') }}</div>
        <default-pull-down-menu
          v-model="type"
          :items="lifeSpanOption"
          :initialValue="type"
          :placeHolderText="$t('casbee.pulldown_select_choice')"
          :width="widthPulldownByText(lifeSpanOption)"
          height="32px"
          :isCasbeePulldown="true"
          class="casbee-structure casbee-pulldown"
          :class="{ 'error-pulldown': isError }"
          :disabled="isViewMode"
        />
        <p class="error-pulldown" v-if="isError">{{ errorPulldownMessage }}</p>
      </div>
      <div class="select-emission">
        <div class="emission-title">{{ $t('casbee.label_co2_reduction_measures') }}</div>
        <default-pull-down-menu
          v-model="material"
          :items="materialList"
          :initialValue="material"
          :placeHolderText="`アイテムを選択`"
          :width="pullDownWidth"
          height="32px"
          :isCasbeePulldown="true"
          class="casbee-reduceEmission casbee-pulldown"
          :class="{ 'disabled-pulldown': isDisabled }"
          :disabled="isViewMode"
        />
      </div>
      <div class="co2-emission">
        <span class="co2-emission__title">{{ $t('casbee.label_emissions') }}</span>
        <div class="co2-emission__radio">
          <v-radio-group v-model="selectModel" :disabled="isViewMode">
            <v-radio :label="$t('casbee.radio_select_no_solar_power')" :value="$t('casbee.radio_select_no_solar_power')" :ripple="false"></v-radio>
            <v-radio :label="$t('casbee.radio_select_solar_power')" :value="$t('casbee.radio_select_solar_power')" :ripple="false"></v-radio>
          </v-radio-group>
        </div>
        <div class="solar-energy" v-show="calSolar">
          <div class="energy-source">
            <span class="energy-title">{{ $t('casbee.label_enegy_annual_total') }}</span>
            <div>
              <div class="energy-input">
                <casbee-input
                  v-model="energySource"
                  :value="energySource"
                  :label="''"
                  :placeholderText="$t('casbee.placeholder_enter_input_casbee')"
                  width="200"
                  :errorMessage="inputErrors.energySource"
                  @removeError="inputErrors.energySource = ''"
                  :disabled="isViewMode"
                />
              </div>
            </div>
          </div>
          <div class="acreage-source">
            <span class="acreage-title">{{ $t('casbee.label_total_floor_area') }}</span>
            <div>
              <div class="acreage-input energy-input">
                <casbee-input
                  v-model="acreageSource"
                  :value="acreageSource"
                  label="㎡"
                  width="200"
                  :placeholderText="$t('casbee.placeholder_enter_input_casbee')"
                  :errorMessage="inputErrors.acreageSource"
                  @removeError="inputErrors.acreageSource = ''"
                  :disabled="isViewMode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <component
        v-if="selectModel !== null"
        :is="optionComponent"
        @onHandlerCalculationData="onHandlerCalculationData"
      />
    </v-form>
    <common-button
      v-if="!isViewMode"
      class="calcButton"
      v-ripple="false"
      type="colored"
      :label="$t('casbee.button_calc_value_inputed')"
      :isDisable="isViewMode"
      @action="onHandlerCalculationData()"
    />
  </div>
</template>
<script>
import { CASBEE_DATA, CO2X_DISCOUNT_DATA, LONGVITY, INPUT_DATA } from '@/constants/home-category-1/casbee-home';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import CommonButton from '@/components/utils/button.vue';
import CasbeeInput from '@/components/casbee/input';
import { validateInputFieldCasbee } from '@/utils/validate';
import { CASBEE_TYPE } from '@/constants/casbee/';
import {evaluate, forEach} from 'mathjs'
import {getWidthByTextContent} from '@/utils/calcTextWidth'
export default {
  components: { DefaultPullDownMenu, CommonButton, CasbeeInput },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      type: null,
      materialList: [],
      material: 0,
      age: 30,
      ageOption: [],
      selectModel: INPUT_DATA.non_equipment,
      totalCO2: 0,
      energySource: null,
      acreageSource: null,
      inputErrors: {
        energySource: '',
        acreageSource: '',
      },

      calSolar: false,
      errorPulldownMessage: this.$t("casbee.error_enter_input_title_casbee"),
      isError: false,
      isDisabled: true,
      pullDownWidth: '740px',
    };
  },

  mounted() {
    this.getAgeOption();
    this.prepareDetailData();
    this.pullDownWidth = this.getWidth();
    this.onWindowResize();
  },
  computed: {
    lifeSpanOption() {
      const option = [];
      const dt = Object.keys(CASBEE_DATA).forEach((key) => {
        option.push({
          name: CASBEE_DATA[key][0].name,
          value: key,
        });
      });
      return option;
    },
    optionComponent() {
      if (this.selectModel === INPUT_DATA.non_equipment) {
        this.calSolar = false;
      } else {
        this.calSolar = true;
      }
    },
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },

    isViewMode() {
      return this.detailData.hasOwnProperty('dataDetail');
    },
  },
  methods: {
    widthPulldownByText(dataList, widthJa) {
      if(this.$i18n.locale === 'ja') {
        return widthJa;
      }
      let widthListPulldowns = [];
      dataList.forEach(item => {
        widthListPulldowns.push(getWidthByTextContent(item.name));
      });
      return Math.max(...widthListPulldowns) + 'px';
    },
    getAgeOption() {
      this.ageOption = Object.keys(LONGVITY).map((item) => {
        return { name: LONGVITY[item], value: LONGVITY[item] };
      });
    },
    validateCalcData() {
      if (this.type === null) {
        this.isError = true;
      }
      if (this.selectModel !== INPUT_DATA.equipment) return;
      Object.keys(this.inputErrors).forEach((key) => {
        this.inputErrors[key] = validateInputFieldCasbee(this[key]);
      });
    },
    buildResultData() {
      let numberFrame = CASBEE_DATA[this.type]?.find((item) => item.value == this.age)?.number;
      let numberMaterial = CO2X_DISCOUNT_DATA[this.type]?.find((item) => item.value === this.material).number;
      let calCo2Data =  evaluate(`${numberFrame} * (1 - ${numberMaterial})`);
      let calSolarData = 0 
      if (!(this.energySource == 0 || this.acreageSource == 0)) {
        calSolarData = evaluate(`(${+this.energySource} * 3 * 0.0507) / ${+this.acreageSource} / 1000 / ${+this.age}`);
      }
      if (this.selectModel === INPUT_DATA.non_equipment) {
        let totalHomeCategory1 = evaluate(`${calCo2Data}`);
        this.totalCO2 = totalHomeCategory1.toFixed(4).toString();
      } else {
        let totalHomeCategory1 = evaluate(`${calCo2Data} + ${calSolarData}`);
        this.totalCO2 = totalHomeCategory1.toFixed(4).toString();
      }
      const typeName = CASBEE_DATA[this.type][0].name;
      const materialName = CO2X_DISCOUNT_DATA[this.type]?.find((item) => item.value === this.material).source;
      const age = this.age;
      const selectModel = this.selectModel;
      return {
        total: this.totalCO2,
        source: this.$t('casbee.input_field_source_text_home_1', {typeName, materialName, age, selectModel}),
        label: this.$t("casbee.description_emission_co2_per_m2"),
        labelSource: this.$t("casbee.description_label_source_home"),
        patternType: CASBEE_TYPE.HOME,
        content_json: {
          age: this.age,
          type: this.type,
          material: this.material,
          selectModel: this.selectModel,
          energySource: this.energySource,
          acreageSource: this.acreageSource,
        },
      };
    },
    onHandlerCalculationData() {
      this.validateCalcData();
      if (this.isError) return;
      if (this.isValidData) {
        const result = this.buildResultData();
        this.$emit('onHandlerCalculationData', result);
      } else {
        Object.keys(this.inputErrors).forEach((key) => {
          if(Number(this[key]) < 0 || isNaN(Number(this[key]))) {
            this[key] = null;
          }
          return this[key]
        });
      }
    },

    prepareDetailData() {
      if (Object.keys(this.detailData).length > 0) {
        const detailData = this.detailData.dataDetail.content_json;
        Object.keys(detailData).forEach((key) => {
          this[key] = detailData[key];
        });
        this.onHandlerCalculationData();
      }
    },
    getWidth() {
      if (window.innerWidth >= 1024) {
        let textLength = this.widthPulldownByText(this.materialList, '740px');

        // 316 = 40 padding * 2 + left menu
        if((parseFloat(textLength) + 316 > window.innerWidth) && this.$i18n.locale !== 'ja') {
          return window.innerWidth - 316 + 'px';
        }
        return this.widthPulldownByText(this.materialList, '740px');
      } else if (window.innerWidth >= 375) {
        return '100%';
      }
      return '100%';
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.pullDownWidth = this.getWidth();
      }, 100);
    },
  },

  watch: {
    selectModel() {
      if (!this.isViewMode) {
        this.energySource = '';
        this.acreageSource = '';
        this.inputErrors.energySource = '';
        this.inputErrors.acreageSource = '';
        this.isError = false;
      }
    },
    type() {
      this.materialList = CO2X_DISCOUNT_DATA[this.type];
      if (this.type) {
        this.isError = false;
        this.isDisabled = false;
      }
      this.pullDownWidth = this.getWidth();
    },
  },
};
</script>
<style lang="scss" scoped>
.select-longvity {
  .longvity-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin-bottom: 8px;
  }
  ::v-deep .selection-box-bridge .selection-box .input-box {
    justify-content: center;
  }
}
.select-structure {
  margin-top: 48px;
  .structure-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin-bottom: 8px;
  }
  ::v-deep .error-pulldown {
    color: $redMid;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    &.pulldown .selection-box-bridge .selection-box {
      border: 1px solid $redMid;
      border-radius: 6px;
    }
  }
}
.select-emission {
  margin-top: 48px;
  .emission-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin-bottom: 8px;
  }
  ::v-deep .disabled-pulldown {
    &.pulldown .selection-box-bridge .selection-box {
      background: $monoLight;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      border: none;
      .input-icon .img {
        display: none;
      }
      .input-icon .none-image {
        display: none;
      }
      .input-icon .disabled-image {
        display: block;
      }
    }
  }
}
.co2-emission {
  margin-top: 48px;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
  }
  &__radio {
    margin-top: 8px;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;
    padding: 12px 16px;
    .v-input--radio-group--row .v-input--radio-group__input {
      flex-direction: column;
      flex-wrap: none;
      margin: 0;
    }
    .v-input--selection-controls {
      margin: 0;
      padding: 0;
    }
    .v-radio {
      display: flex;
      gap: 6px;
      > ::v-deep .v-label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        bottom: 2px;
      }
      &:first-child {
        padding-bottom: 18px;
        margin: 0 !important;
      }
    }
    ::v-deep .v-messages {
      display: none;
    }
  }
  ::v-deep .v-input__control .v-input__slot .v-radio .v-input--selection-controls__input {
    margin: 0 !important;
  }
}
.solar-energy {
  margin-top: 8px;
  .energy-source {
    .energy-title {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoDark;
    }
    .energy-input {
      margin-top: 8px;
    }
  }
  .acreage-source {
    margin-top: 8px;
    line-height: 1;
    .acreage-title {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoDark;
    }
    .acreage-input {
      margin-top: 8px;
    }
  }
}
.calcButton {
  margin-top: 48px;
  width: 100% !important;
  height: 56px !important;
}
.solar-energy {
  ::v-deep .casbee-input .input-wrapper .input .input-elic {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    box-shadow: unset;
    height: 32px;
    padding: 0 16px;
    text-align: right;
    background: $monoWhite;
    border: 1px solid $spanishGray;
    border-radius: 4px;
  }
  ::v-deep .casbee-input .input-wrapper .input .warning-icon {
    right: 8px;
    top: 8px;
  }
}

@include desktop {
  .select-longvity {
    .longvity-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.05em;
      margin-bottom: 12px;
    }
    ::v-deep .selection-box-bridge .selection-box .input-box {
      justify-content: end;
    }
  }
  .select-structure {
    .structure-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.05em;
      margin-bottom: 12px;
    }
    ::v-deep .error-pulldown {
      color: $redMid;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.05em;
      &.pulldown .selection-box-bridge .selection-box {
        border: 1px solid $redMid;
      }
    }
  }
  .select-emission {
    .emission-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.05em;
    }
    ::v-deep .disabled-pulldown {
      &.pulldown .selection-box-bridge .selection-box {
        background: $monoLight;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        border: none;
      }
    }
  }
  .co2-emission {
    &__title {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.05em;
    }
    &__radio {
      margin: 28px 0;
      line-height: 1;
      border: none;
      padding: 0;
      ::v-deep .v-input--radio-group--column .v-input--radio-group__input {
        flex-direction: row;
      }
      .v-radio {
        gap: 8px;
        &:first-child {
          margin: 0 !important;
          padding-bottom: 0;
          padding-right: 20px;
        }
      }
    }
  }
  .solar-energy {
    margin-top: 0;
    .energy-source {
      .energy-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $monoDark;
      }
      .energy-input {
        margin-top: 12px;
      }
    }
    .acreage-source {
      margin-top: 20px;
      line-height: 1;
      .acreage-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $monoDark;
      }
      .acreage-input {
        margin-top: 12px;
      }
    }
  }
  .calcButton {
    width: max-content !important;
    height: 56px !important;
  }
  .solar-energy {
    ::v-deep .casbee-input .input-wrapper .input .input-elic {
      padding: 0 30px 0 8px;
      background: $monoWhite;
      box-shadow: unset;
      height: 32px;
      border-radius: 6px;
      border: 1px solid $spanishGray;
    }
    ::v-deep .casbee-input .input-wrapper .input .warning-icon {
      right: 8px;
      top: 8px;
    }
  }
}
</style>

